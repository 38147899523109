import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UtilsService {

  constructor() { }

  goToSection(id: string): void {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
  }
}
