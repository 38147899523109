<section class="page">
  <footer class="footer" style="background-image: url('assets/img/background-footer.png')">
    <div class="footer__content">
      <div class="footer__content-column">
        <div class="footer__content-text"><img class="footer__logo" src="assets/img/logo.png" alt="logo">
          <div class="footer__paragraph">Somos una empresa cuyo enfoque principal es proporcionar un servicio de
            calidad que se ajuste a las necesidades de nuestros clientes. Establecemos el análisis necesario para
            desarrollar soluciones de software que satisfagan.</div>
        </div>
      </div>
      <div class="footer__content-column footer__content-column-2">
        <div class="footer__title">Servicios</div>
        <ul class="footer__menu">
          <li class="footer__menu-item"><a class="footer__link"> <span>- Applications development</span></a></li>
          <li class="footer__menu-item"><a class="footer__link"> <span>- Mainframe</span></a></li>
          <li class="footer__menu-item"><a class="footer__link"> <span>- Portals and design Thinking</span></a></li>
          <li class="footer__menu-item"><a class="footer__link"> <span>- Quality Solutions</span></a></li>
          <li class="footer__menu-item"><a class="footer__link"> <span>- Applications Outsourcing</span></a></li>
          <li class="footer__menu-item"><a class="footer__link"> <span>- Business Intelligence</span></a></li>
          <li class="footer__menu-item"><a class="footer__link"> <span>- Mobile</span></a></li>
          <li class="footer__menu-item"><a class="footer__link"> <span>- Project and Process Governance</span></a>
          </li>
          <li class="footer__menu-item"><a class="footer__link"> <span>- Enterprise Solutions</span></a></li>
          <li class="footer__menu-item"><a class="footer__link"> <span>- Cloud Computing</span></a></li>
          <li class="footer__menu-item"><a class="footer__link"> <span>- Infraestucture Outsourcing</span></a></li>
          <li class="footer__menu-item"><a class="footer__link"> <span>- Architecture and Integrations</span></a></li>
          <li class="footer__menu-item"><a class="footer__link"> <span>- Service Desk</span></a></li>
          <li class="footer__menu-item"><a class="footer__link"> <span>- Shared Services</span></a></li>
          <li class="footer__menu-item"><a class="footer__link"> <span>- Chatbot</span></a></li>
        </ul>
      </div>
      <div class="footer__content-column">
        <div class="footer__title">Nuestros Proyectos</div>
        <div class="footer__content-text">
          <div class="footer__paragraph">Nuestra oferta de sofware es basada en altos estandares de calidad, nuestros profesionales estan capacitados y con basta experiencia en diferentes tecnologias. Nos adaptamos a las necesidades de nuestros clientes y somo siempre con propuestas y ofertas competitivas. </div>
        </div>
      </div>
      <div class="footer__content-column">
        <div class="footer__title">Contacto</div>
        <div class="footer__content-contact">
          <a class="footer__link nowrap" href="mailto:administracion@innovationtc.com">
            <i class="fas fa-envelope"></i>
            <span>administracion@innovationtc.com</span>
          </a>
          <a class="footer__link nowrap" href="tel:+50499622008">
            <i class="fas fa-phone"></i>
            <span>9962-2008</span>
          </a>
        </div>
        <div class="footer__title">Síguenos</div>
        <div class="footer__content-social">
            <a class="footer__link--social" href="/linkedin"><i class="fab fa-linkedin-in"></i></a>
            <a class="footer__link--social" href="/facebook"><i class="fab fa-facebook-f"></i></a>
            <a class="footer__link--social" href="/twitter"><i class="fab fa-twitter"></i></a>
            <a class="footer__link--social" href="/instagram"><i class="fab fa-instagram"></i></a>
        </div>
      </div>
    </div>
    <div class="footer__copyright">© 2020 innovationTC | All Right Reserved</div>
  </footer>
</section>
