import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  { path: 'inicio', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'nosotros', loadChildren: () => import('./modules/about-us/about-us.module').then(m => m.AboutUsModule) },
  { path: 'proyectos', loadChildren: () => import('./modules/our-projects/our-projects.module').then(m => m.OurProjectsModule) },
  { path: 'contacto', loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
