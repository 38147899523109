<nav class="main-menu" id="nav">
  <div class="container">
    <div class="logo-container">
      <a routerLink="/">
        <img class="logo" src="assets/img/logo.png" alt="logo">
      </a>
    </div>
    <button class="menu-mobile" (click)="setMobileMenu()" ><i class="fas fa-bars" aria-hidden="true"></i></button>
    <div class="content-aligned-right" [ngClass]="{show: showMobileMenu}">
      <ul class="menu menu--level-01">
        <li class="menu-item">
          <a class="menu-link" [ngClass]="{'menu-link--active': routes.home.active}"
            (click)="goToSection('/inicio', 'hero')">Inicio</a>
        </li>
        <li class="menu-item menu-item--has-menu">
          <a class="menu-link" [ngClass]="{'menu-link--active': routes.aboutUs.active }" href="javascript:;">Nosotros</a>
          <i class="fa fa-chevron-down menu-arrow" aria-hidden="true"></i>
          <ul class="menu menu--level-02">
            <li class="menu-item">
              <a class="menu-link" (click)="goToSection('/nosotros', 'valores')">Valores</a>

            </li>
            <!-- <li class="menu-item">
              <a class="menu-link" (click)="goToSection('/nosotros', 'equipo')">Team/Equipo</a>
            </li> -->
          </ul>
        </li>
        <li class="menu-item">
          <a class="menu-link" [ngClass]="{'menu-link--active': routes.services.active }" (click)="goToSection('/inicio', 'services')">Servicios</a>
        </li>
        <li class="menu-item menu-item--has-menu">
          <a class="menu-link" [ngClass]="{'menu-link--active': routes.ourProjects.active }" href="javascript:;">Nuestros Proyectos</a>
          <i class="fa fa-chevron-down menu-arrow" aria-hidden="true"></i>
          <ul class="menu menu--level-02">
            <li class="menu-item">
              <a class="menu-link" (click)="goToSection('/proyectos', undefined, 'innovacion')">Innovación</a>
            </li>
            <li class="menu-item">
              <a class="menu-link" (click)="goToSection('/proyectos', undefined, 'desarrollo')">Desarrollo</a>

            </li>
          </ul>
        </li>
        <li class="menu-item">
          <a class="menu-link" [ngClass]="{'menu-link--active': routes.contact.active}" (click)="goToSection('/inicio','contact')">Contacto</a>
        </li>
      </ul>
    </div>
  </div>
</nav>