import { Component, OnInit, AfterContentInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, AfterContentInit {
  routes: any;
  showMobileMenu: boolean;

  constructor(
    private router: Router,
    private utilsService: UtilsService
    ) { }

  ngOnInit(): void {
    this.showMobileMenu = false;
    this.routes = {
      home: {
        active: false,
        isRegex: false,
        section: ['hero'],
        url: ['/', '/inicio', '/inicio#hero']
      },
      aboutUs: {
        active: false,
        isRegex: true,
        section: ['equipo', 'valores'],
        url: [/nosotros/]
      },
      services: {
        active: false,
        isRegex: false,
        section: ['services'],
        url: ['/inicio#services']
      },
      ourProjects: {
        active: false,
        isRegex: true,
        section: [],
        url: [/proyectos/]
      },
      contact: {
        active: false,
        isRegex: false,
        section: ['contact'],
        url: ['/inicio#contact']
      },
    };

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.getActiveRoute(event.url);
      }
    });
  }

  getActiveRoute(url: string): void {
    const keys = Object.keys(this.routes);

    keys.forEach(element => {
      if (this.routes[element].isRegex && this.routes[element].url[0].test(url) ) {
        this.routes[element].active = true;
      } else {
        if (this.routes[element].url.indexOf(url) !== -1) {
          this.routes[element].active = true;
        } else {
          this.routes[element].active = false;
        }
      }
    });
  }

  setMobileMenu(): void {
    this.showMobileMenu = !this.showMobileMenu;
  }

  goToSection(route: string, section?: string, param?: string): void {
    const actualRoute  = this.router.url.split('#')[0];
    const keys = Object.keys(this.routes);

    keys.forEach(element => {
      this.routes[element].active = this.routes[element].section.indexOf(section) !== -1 ? true : false;
    });

    if (actualRoute !== route) {
      param ?
        this.router.navigate([route, param], { fragment: section }) :
        this.router.navigate([route], { fragment: section });
    } else {
      this.utilsService.goToSection(section);
    }

    this.setMobileMenu();
  }

  ngAfterContentInit() {
    (() => {
      const nav = document.getElementById('nav');
      window.addEventListener('scroll', () => {
        if (window.scrollY > 1.5) {
          nav.classList.add('sticky');
          document.body.style.paddingTop = '70';
        } else {
          nav.classList.remove('sticky');
          document.body.style.paddingTop = '0';
        }
      });
    })();
  }
}
